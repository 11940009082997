import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";

import {
  getAllB2BLead,
  getAllLead,
  leadSquareKeys,
  Qualification,
} from "../services/leadsquareapi";

function ViewB2BLead() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [leaddata, setleadData] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    leadData().then((data) => {
      setleadData(data.data);
      console.log(data.data);
      setLoading(false);
    });

    async function leadData() {
      setLoading(true);
      return await getAllB2BLead();
    }
    //to get the array to be displayed
  }, []);

  return (
    <>
      <TableContainer className="mainTable">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className="leadtablefont">
              <TableCell>_id</TableCell>
              <TableCell>FirstName</TableCell>
              <TableCell>CountryCode</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>EmailAddress</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>mx_Training_Location</TableCell>
              <TableCell>mx_Website_Page_URL</TableCell>
              <TableCell>founderLastName</TableCell>
              <TableCell>CompanyName</TableCell>
              <TableCell>industry</TableCell>
              <TableCell>yearOfFounding</TableCell>
              <TableCell>foundingCountry</TableCell>
              <TableCell>foundingStory</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>JobTitle</TableCell>
              <TableCell>No_Of_Employees</TableCell>
              <TableCell>LeadSource</TableCell>
              <TableCell>a_tnc</TableCell>
              <TableCell>leadId</TableCell>
              <TableCell>responseFromZoho</TableCell>
              <TableCell>isLeadSavedOnZoho</TableCell>
              <TableCell>agreeToCourseUpdatesOnWhatsAppOrSms</TableCell>
              <TableCell>mx_Campaign_Program</TableCell>
              <TableCell>mx_utm_medium</TableCell>
              <TableCell>mx_Website_Source_Campaign</TableCell>
              <TableCell>mx_keyword_utm_term</TableCell>
              <TableCell>mx_Lead_Source_Detail</TableCell>
              <TableCell>mx_ad_group</TableCell>
              <TableCell>mx_Campaign_type</TableCell>
              <TableCell>createdAt</TableCell>
              <TableCell>updatedAt</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : leaddata?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {leaddata.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row.index}>
                      <TableCell>{row._id || "--"}</TableCell>
                      <TableCell>{row.FirstName || "--"}</TableCell>
                      <TableCell>{row.CountryCode || "--"}</TableCell>
                      <TableCell>{row.Phone || "--"}</TableCell>
                      <TableCell>{row.Subject || "--"}</TableCell>
                      <TableCell>{row.Country || "--"}</TableCell>
                      <TableCell>{row.EmailAddress || "--"}</TableCell>
                      <TableCell>{row.Designation || "--"}</TableCell>
                      <TableCell>{row.mx_Training_Location || "--"}</TableCell>
                      <TableCell>{row.mx_Website_Page_URL || "--"}</TableCell>
                      <TableCell>{row.founderLastName || "--"}</TableCell>
                      <TableCell>{row.CompanyName || "--"}</TableCell>
                      <TableCell>{row.industry || "--"}</TableCell>
                      <TableCell>{row.yearOfFounding || "--"}</TableCell>
                      <TableCell>{row.foundingCountry || "--"}</TableCell>

                      <TableCell>{row.foundingStory || "--"}</TableCell>
                      <TableCell>{row.Message || "--"}</TableCell>
                      <TableCell>{row.JobTitle || "--"}</TableCell>
                      <TableCell>{row.No_Of_Employees || "--"}</TableCell>
                      <TableCell>{row.LeadSource || "--"}</TableCell>
                      <TableCell>{row.a_tnc ? "true" : "false"}</TableCell>
                      <TableCell>{row.leadId || "--"}</TableCell>
                      <TableCell>{row.responseFromZoho || "--"}</TableCell>
                      <TableCell>
                        {row.isLeadSavedOnZoho ? "true" : "false"}
                      </TableCell>
                      <TableCell>
                        {row.agreeToCourseUpdatesOnWhatsAppOrSms
                          ? "true"
                          : "false"}
                      </TableCell>
                      <TableCell>{row.mx_Campaign_Program || "--"}</TableCell>
                      <TableCell>{row.mx_utm_medium || "--"}</TableCell>
                      <TableCell>
                        {row.mx_Website_Source_Campaign || "--"}
                      </TableCell>
                      <TableCell>{row.mx_keyword_utm_term || "--"}</TableCell>
                      <TableCell>{row.mx_Lead_Source_Detail || "--"}</TableCell>
                      <TableCell>{row.mx_ad_group || "--"}</TableCell>
                      <TableCell>{row.mx_Campaign_type || "--"}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ViewB2BLead;
